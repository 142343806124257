import request from '~/common/utils/request'

export function login (data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function register (data) {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function loginGoogle (data) {
  return request({
    url: '/user/social-login/googlev2',
    method: 'post',
    data
  })
}

export function loginFacebook (data) {
  return request({
    url: '/user/social-login/facebookv2',
    method: 'post',
    data
  })
}

export function getInfo (token) {
  if (!token) {
    return request({
      url: '/profile/user',
      method: 'get'
    })
  } else {
    return request({
      url: '/profile/user',
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export function forgetPassword (mobile) {
  return request({
    url: '/user/forgotPassword',
    method: 'get',
    params: {
      mobile,
      platform: 'app'
    }
  })
}

export function deleteUser (data) {
  return request({
    url: '/user/delete',
    method: 'post',
    data
  })
}

export function userAccept (data) {
  return request({
    url: '/user/accept-customer',
    method: 'post',
    data
  })
}

export function logoutApi (token) {
  return request({
    url: '/user/logout',
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}
